import { Injectable, OnDestroy } from '@angular/core';
import { Translations } from '@app/core/services/i18n/translations.service';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import {
  AssetFilterSelectionModel,
  ASSET_FILTER_KEY_NAME,
  DropdownOptionsObject,
  FILTER_TYPE,
  SearchableDropdownModel,
  ZONE_FILTER_KEY_NAME,
  ZuiAssetFilterModel,
  ZuiZoneFilterModel,
  SearchFiltersControlModel,
  SEARCH_BAR_KEY_NAME
} from '@zonar-ui/filter';
import { TranslateService } from '@zonar-ui/i18n';
import { BehaviorSubject, Observable, of, Subject, take } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import {
  FiltersBarSelections,
  FiltersParams,
  FiltersState,
  initialFiltersState,
  PowerStatus,
  POWER_STATUS_FILTER_NAME,
  SortAttribute,
  SortOrder
} from '@app/services/filters.model';
import { CurrentCompanyService } from './current-company.service';
import { environment as env } from '@environments/environment';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { DataDogService } from './data-dog.service';
import { ZonesService } from '@app/modules/zones/services/zones.service';
import { Resources, Zone } from '@app/modules/zones/zones.model';
import { Params, Router } from '@angular/router';
import { LeafletZoneService } from '@app/modules/zones/services/leaflet-zone.service';

@Injectable({
  providedIn: 'root'
})
export class FiltersService implements OnDestroy {
  private translated;
  private onDestroy$ = new Subject<void>();

  private filtersState = new BehaviorSubject<FiltersState>(initialFiltersState);
  private searchTerm$ = new BehaviorSubject<string>('');
  private resetCallback: Function;

  constructor(
    private translateService: TranslateService,
    private translations: Translations,
    private currentCompanyService: CurrentCompanyService,
    private zonesService: ZonesService,
    private leafletZoneService: LeafletZoneService,
    private locationFacade: LocationFacade,
    private router: Router,
    private dataDog: DataDogService
  ) {
    this.translations.translationsLoadState
      .pipe(
        takeUntil(this.onDestroy$),
        filter(loadstate => loadstate === ResourceLoadState.LOAD_SUCCESSFUL)
      )
      .subscribe(() => {
        this.translated = this.translateService.instant([
          this.translations.filters.filterNames.assetid,
          this.translations.filters.powerStatus.poweredOffAssets,
          this.translations.filters.powerStatus.poweredOnAssets,
          this.translations.filters.filterNames.powerStatus,
          this.translations.filters.filterNames.zone,
          this.translations.filters.filterNames.assetOrDriverSearch
        ]);
      });

    this.currentCompanyService
      .getCurrentCompanyId()
      .pipe(
        filter(id => id != null),
        distinctUntilChanged(),
        takeUntil(this.onDestroy$)
      )
      .subscribe(id => {
        const currentState = this.filtersState.getValue();
        // if it is a company change, reset filters to initial state with the new id
        if (currentState.companyId != null && currentState.companyId != id) {
          this.filtersState.next({ ...initialFiltersState, companyId: id } as FiltersState);
          this.resetCallback();
          this.updateUrlParamsFromFiltersState();
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setResetCallback(callback: Function): void {
    this.resetCallback = callback;
  }

  getFiltersState(): Observable<FiltersState> {
    return this.filtersState;
  }

  setStateFromFiltersBar(selections: FiltersBarSelections) {
    this.searchTerm$.next('');
    if (selections[SEARCH_BAR_KEY_NAME]?.assetId) {
      this.locationFacade.clearClusterLayer();
      this.router.navigate(['/assets', selections[SEARCH_BAR_KEY_NAME].assetId, 'live']);
    } else if (selections) {
      // make sure we have a current company to prevent race condition during bootstrap
      this.currentCompanyService
        .getCurrentCompanyId()
        .pipe(
          filter(id => id != null),
          take(1)
        )
        .subscribe(companyId => {
          let newFiltersState: FiltersState = { ...this.filtersState.getValue(), companyId: companyId };
          // until we refactor for multiple zones (at which point we will only use ids since the datastore will be enriched with zone ids)
          // we must do some async work to get the geojson for a zone by its id
          // so we set up this behavior subject with a load status to make sure we are not updating filters state until those calls are done
          // to prevent various race conditions compared to the display of the filters bar
          const zoneFilterUpdateStatus$ = new BehaviorSubject(ResourceLoadState.INITIAL);
          for (const filter in selections) {
            const val = selections[filter];
            switch (filter) {
              case ASSET_FILTER_KEY_NAME:
                const assetFilterSelection = val as AssetFilterSelectionModel;
                // if all assets option is selected, default to no explicit division or asset id filters
                if (val.isAllSelected) {
                  newFiltersState.divisionIds = [];
                  newFiltersState.assetIds = [];
                } else {
                  newFiltersState.divisionIds = assetFilterSelection.selected.divisionIds;
                  newFiltersState.assetIds = assetFilterSelection.selected.assetIds;
                }
                break;
              case ZONE_FILTER_KEY_NAME:
                if (val && val.id) {
                  zoneFilterUpdateStatus$.next(ResourceLoadState.LOADING);
                  this.zonesService.getResourceById(val.id, Resources.ZONES).subscribe((zone: Zone) => {
                    // if zones layer isn't enabled, enable now
                    this.leafletZoneService
                      .getZonesEnabled()
                      .pipe(take(1))
                      .subscribe(ze => {
                        if (!ze) this.leafletZoneService.addZonesToMap();
                      });
                    newFiltersState.geojson = JSON.stringify(
                      (zone.geometry.features.find(f => f.properties.name == 'GEOMETRY') || zone.geometry.features[0])
                        .geometry
                    );
                    newFiltersState.zoneIds = [zone.id];
                    zoneFilterUpdateStatus$.next(ResourceLoadState.LOAD_SUCCESSFUL);
                  });
                } else {
                  newFiltersState.geojson = null;
                  newFiltersState.zoneIds = [];
                }
                break;
              case POWER_STATUS_FILTER_NAME:
                if (val.isAllSelected) {
                  newFiltersState.powerOn = null; // if both selected, all assets
                } else if (val.selected.length) {
                  newFiltersState.powerOn = val.selected[0];
                } else {
                  newFiltersState.powerOn = null;
                }
                break;
              case SEARCH_BAR_KEY_NAME:
                if (val) {
                  const { showAll, value, driverFirstName, driverLastName } = val;

                  newFiltersState.searchTerms = showAll ? [value] : [driverFirstName, driverLastName];

                  if (showAll) {
                    this.searchTerm$.next(value);
                    this.dataDog.addRumAction('fuzzy_search', { searchTerm: value });
                  }
                } else {
                  newFiltersState.searchTerms = [];
                }
                break;
            }
          }
          zoneFilterUpdateStatus$
            .pipe(
              filter(ls => {
                return ls == ResourceLoadState.INITIAL || ls == ResourceLoadState.LOAD_SUCCESSFUL;
              }),
              take(1)
            )
            .subscribe(_ => {
              this.filtersState.next(newFiltersState);
              this.updateUrlParamsFromFiltersState();
            });
        });
    }
  }

  setSortingState(sortAttribute: SortAttribute, sortOrder: SortOrder) {
    this.filtersState.next({
      ...this.filtersState.getValue(),
      sortAttribute: sortAttribute,
      sortOrder: sortOrder
    } as FiltersState);
  }

  getFilterList(
    isDesktopView = true
  ): (ZuiAssetFilterModel | ZuiZoneFilterModel | SearchableDropdownModel | SearchFiltersControlModel)[] {
    let filterList: (ZuiAssetFilterModel | ZuiZoneFilterModel | SearchableDropdownModel | SearchFiltersControlModel)[] =
      [
        {
          type: FILTER_TYPE.ASSET,
          options: {
            label: this.translated[this.translations.filters.filterNames.assetid],
            paramName: ['division_id', 'asset_id']
          }
        } as ZuiAssetFilterModel,
        // TODO: this is where the asset properties filter goes
        {
          type: FILTER_TYPE.ZONE_FILTER,
          options: {
            paramName: 'zone_id',
            uniqueDropdownName: this.translated[this.translations.filters.filterNames.zone]
          }
        } as ZuiZoneFilterModel,
        {
          type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
          options: {
            label: this.translated[this.translations.filters.filterNames.powerStatus],
            data: of([
              {
                title: this.translated[this.translations.filters.powerStatus.poweredOffAssets],
                value: PowerStatus.OFF
              },
              {
                title: this.translated[this.translations.filters.powerStatus.poweredOnAssets],
                value: PowerStatus.ON
              }
            ] as DropdownOptionsObject[]) as unknown, // TODO: this as unknown should go away, but doing so gives a type error which is probably due to rxjs version disagreement between maps and PL package?
            isMultiple: true,
            fgControlName: POWER_STATUS_FILTER_NAME,
            blueCheckmarks: true,
            inputParams: [],
            enableAllOptions: true,
            paramName: POWER_STATUS_FILTER_NAME
          }
        } as SearchableDropdownModel
      ];
    if (isDesktopView) {
      filterList = [this.getSearchFilterOption(), ...filterList];
    }
    return filterList;
  }

  updateUrlParamsFromFiltersState(): void {
    const fs = this.filtersState.getValue();
    if (fs.companyId == null) return;
    const queryParams: FiltersParams = {
      asset_id: fs.assetIds.length ? fs.assetIds : null,
      company_id: fs.companyId,
      division_id: fs.divisionIds.length ? fs.divisionIds : null,
      power_status: fs.powerOn,
      zone_id: fs.zoneIds.length ? fs.zoneIds : null
    };
    this.router.navigate(['assets'], {
      queryParams: queryParams as Params,
      queryParamsHandling: 'merge'
    });
  }

  getSearchFilterOption(): SearchFiltersControlModel {
    const searchFiltersControlOption = {
      type: FILTER_TYPE.SEARCH_BAR,
      options: {
        label: this.translated[this.translations.filters.filterNames.assetOrDriverSearch],
        autocompleteMinQueryLength: 2,
        paramName: 'search_bar',
        pageSize: env.autocompleteApi.pageSize,
        searchTerm: this.searchTerm$.getValue()
      }
    } as SearchFiltersControlModel;

    return searchFiltersControlOption;
  }
}
