import {
  AssetFilterSelectionModel,
  ASSET_FILTER_KEY_NAME,
  ZONE_FILTER_KEY_NAME,
  SEARCH_BAR_KEY_NAME
} from '@zonar-ui/filter';

export const POWER_STATUS_FILTER_NAME = 'power_status';
export interface FiltersBarSelections {
  [ASSET_FILTER_KEY_NAME]?: AssetFilterSelectionModel;
  [ZONE_FILTER_KEY_NAME]?: {
    id: string;
    name: string;
  };
  [SEARCH_BAR_KEY_NAME]?: {
    assetId?: string;
    driverProfileId?: string;
    driverFirstName?: string;
    driverLastName?: string;
    value?: string;
  };
  [POWER_STATUS_FILTER_NAME]?: {
    selected: string[]; // [true, false] if both selected, but in this case isAllSelected should also be true
    isAllSelected: boolean;
  };
}

export type SortOrder = 'asc' | 'desc';
export enum SortAttribute {
  ASSET_NAME = 'assetName',
  DRIVER_NAME = 'driverName',
  UPDATES = 'geoEventTs'
}

export interface FilterLatLng {
  0: number; // latitude
  1: number; // longitude
}

export enum PowerStatus {
  ON = 'on',
  OFF = 'off'
}

export interface FiltersState {
  powerOn: PowerStatus;
  companyId: string;
  assetIds: string[];
  divisionIds: string[];
  searchTerms: string[];
  zoneIds: string[];
  geojson: string;
  sortOrder: SortOrder;
  sortAttribute: SortAttribute;
  northEast: FilterLatLng;
  southWest: FilterLatLng;
}

export const initialFiltersState: FiltersState = {
  powerOn: null,
  companyId: null,
  assetIds: [],
  divisionIds: [],
  searchTerms: [],
  zoneIds: [],
  geojson: null,
  sortOrder: null,
  sortAttribute: null,
  northEast: null,
  southWest: null
};

export const mockFiltersState = { ...initialFiltersState, companyId: 'foo' };

export interface FiltersParams {
  asset_id?: Array<string>;
  company_id?: string;
  division_id?: Array<string>;
  power_status?: String;
  zone_id?: Array<string>;
}
