import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { DetailsSubcontext, ViewContext } from '@app/store/layout/reducers/layout.reducer';
import { LoadingAnimationService } from '@app/services/loading-animation.service';
import { Router } from '@angular/router';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { FiltersService } from '@app/services/filters.service';

@Component({
  selector: 'app-selected-asset-navigation',
  templateUrl: './selected-asset-navigation.component.html',
  styleUrls: ['./selected-asset-navigation.component.scss']
})
export class SelectedAssetNavigationComponent implements OnChanges {
  constructor(
    public locationFacade: LocationFacade,
    public loadingAnimationService: LoadingAnimationService,
    public translateService: TranslateService,
    public translations: Translations,
    private filtersService: FiltersService
  ) {}

  @Output() closeButtonClicked = new EventEmitter<boolean>();
  @Output() subContextChange = new EventEmitter<DetailsSubcontext>();
  @Input() asset: ViewableAsset;
  @Input() subContextView: DetailsSubcontext;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.asset.currentValue) {
      this.asset = changes.asset.currentValue;
    }
  }

  handleCloseButtonClick() {
    this.locationFacade.setViewContext(ViewContext.LIST);
    this.locationFacade.setViewSubContext(DetailsSubcontext.LIVE);
    // in case a user's flow is to go /assets/assetId first, then hit close button to return to list, this if statement will initiate all expected animations (loading chip, skeltons etc) when they go to asset list view
    // if a user went to asset list view first, then selected asset, then back to asset list via close button, they should NOT see the animations
    if (this.loadingAnimationService.assetsFetched) {
      this.loadingAnimationService.shouldShowAssetsLoadingAnimations = false;
    }
    this.locationFacade.clearRecentPath();
    this.locationFacade.restoreLiveMap();
    this.closeButtonClicked.emit();
    this.filtersService.updateUrlParamsFromFiltersState(); // restore url to whatever the filter selections were previously
  }

  handleSubContextChange(view: DetailsSubcontext) {
    this.locationFacade.setViewSubContext(view);
    this.locationFacade.setViewContext(ViewContext.DETAILS);
    this.subContextChange.emit(view);
  }
}
